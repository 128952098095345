<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-revenue header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
			<template #title>
				<span class="custom-title-left">Faucet Rowblock</span>
			</template>
			<!-- <p class="text-center my-25 font-semibold text-muted">Or</p> -->
			<a-form id="components-claim-tokens" class="claim-tokens">
				<a-form-item>
					<a-row>
						<a-col :span="24">
							<a-input placeholder="0" v-model="addressReceived" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="text p-10">
								Total Claimed
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ totalClaimed }} ROW
						</a-col>
					</a-row>

					<a-button type="primary" :disabled="!isClaimed" @click="saveData" block
						class="claim-tokens-button text-uppercase">
						Get Tokens
					</a-button>

				</a-form-item>
			</a-form>
		</a-card>
		<!-- / Sign Up Form -->

		<div class="loading" v-if="loading">
		</div>

	</div>
</template>

<script>
import moment from 'moment';

export default ({
	data() {
		return {
			timeUnlock: '',
			poolTotal: '',
			shareTotal: 0,
			rewardAvailable: 0,
			rewardClaimed: 100,
			// rewardClaimed: 10,
			totalClaimed: 0,
			amountAddress: 0,
			tokenAddress: '',
			balanceAddress: 0,
			decimals: 0,
			isClaimed: true,
			loading: false,
			startDate: '',
			serviceApi: '',
			addressReceived: '',
			rpcNode: 'https://rpc.rowblock.org/',
			privateKey: ''
		}
	},
	beforeCreate() {
	},
	mounted() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
	},
	created() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
		this.privateKey = this.$dotenv.VUE_APP_PRIVATE_KEY;
		this.updateTime();
		this.checkAddress();
		this.checkPools()
		setInterval(() => {
			this.checkAddress();
			this.updateTime();
			this.checkPools();
		}, 1000);
	},
	methods: {
		// event every 1 days to update the time
		updateTime() {
			this.startDate = moment().format('Do YYYY, h:mm:ss a');
		},
		checkPools() {
			this.decimals = localStorage.getItem('decimals');
			this.poolTotal = localStorage.getItem('poolTotal');
			console.log('decimals:', this.decimals);
			console.log('poolTotal:', this.poolTotal);
		},
		async claimTokens(body) {
			try {
				let response = await this.$axios.post(`${this.serviceApi}/api/claim-tokens/`, body);
				console.log(response.data);

				if (response.data.status == 'success') {
					this.$swal({
						title: 'Success',
						text: 'Claimed successfully',
						icon: 'success',
						button: 'OK',
					});
					this.isClaimed = false;
					this.checkAddress();
				} else {
					this.$swal({
						title: 'Error',
						text: `A Claimed failed ${response.data.message}`,
						icon: 'error',
						button: 'OK',
					});
				}
			} catch (error) {
				console.log(error);
				this.$swal({
					title: 'Error',
					text: `B Claimed failed ${error}`,
					icon: 'error',
					button: 'OK',
				});
			}
		},
		async saveData() {
			this.loading = true;
			const web3 = new this.$web3(new this.$web3.providers.HttpProvider(this.rpcNode));
			console.log("web3", web3);
			const nonce = await web3.eth.getTransactionCount(localStorage.getItem('address'));
			const tx = {
				gasLimit: web3.utils.toHex(21000),
				gasPrice: web3.utils.toHex(web3.utils.toWei("1", "gwei")),
				to: localStorage.getItem('address'),
				value: web3.utils.toHex(web3.utils.toWei("5", "ether")),
			};

			const signedTx = await web3.eth.accounts.signTransaction(tx, this.privateKey);
			const sentTx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
			console.log("sentTx", sentTx);
			if (sentTx.status == true) {
				let received = this.$crypto.AES.encrypt(localStorage.getItem('address'), "lazy").toString();
				let amount = web3.utils.toWei(this.rewardClaimed.toString(), 'ether');
				let finalAmount = this.$crypto.AES.encrypt(amount.toString(), "lazy").toString();
				let body = {
					address: received,
					amount: finalAmount,
				}
				this.$axios.post(`${this.serviceApi}/send-token/`, body)
					.then((response) => {
						console.log(response);
						this.loading = false;
						const startDate = moment().format('YYYY-MM-DD');
						const endDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');

						if (response.data.status == 'success') {
							let bodyClaimTokens = {
								token_address: this.tokenAddress,
								address: localStorage.getItem('address'),
								date_claimed: moment().format('YYYY-MM-DD HH:mm:ss'),
								tx_hash: response.data.message,
								amount: this.rewardClaimed,
								next_claim_date: endDate,
								balance_before: this.balanceAddress,
								balance: this.balanceAddress,
							}
							this.claimTokens(bodyClaimTokens);
						} else {
							this.$swal({
								title: 'Error',
								text: `C Claimed failed ${response.data.message}`,
								icon: 'error',
								button: 'OK',
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.$swal({
							title: 'Error',
							text: `D Claimed failed ${error}`,
							icon: 'error',
							button: 'OK',
						});
					})
			} else {
				this.$swal({
					title: 'Error',
					text: `E Claimed failed ${error}`,
					icon: 'error',
					button: 'OK',
				});
				this.loading = false;
				return;
			}



		},
		checkAddress() {
			this.balanceAddress = localStorage.getItem('balanceAddress');
			this.balanceAddress = parseFloat(this.balanceAddress).toFixed(0);
			console.log('balanceAddress:', this.balanceAddress);
			this.addressReceived = localStorage.getItem('address');

			let params = {
				address: localStorage.getItem('address'),
			}
			this.$axios.get(`${this.serviceApi}/api/claim-tokens/sum`, { params })
				.then((response) => {
					// if response.data empty then claim 
					if (response.data.length == 0) {
						console.log('C:', "not claimed");
						if (this.minimumAmount > this.balanceAddress) {
							console.log('less than 500k');
							this.isClaimed = false;
						}
					} else {
						console.log('A:', "already claimed");
						let nextClaimDate = response.data[0].next_claim_date;
						nextClaimDate = moment(nextClaimDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

						this.totalClaimed = response.data[0].total_amount;
						console.log('nextClaimDate:', nextClaimDate);

						let dateNow = moment().format('YYYY-MM-DD');
						console.log('dateNow:', dateNow);

						if (dateNow == nextClaimDate) {
							console.log('1:', "already claimed");
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
							this.isClaimed = true;
						} else if (dateNow > nextClaimDate) {
							console.log('2:', "already claimed");
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
							this.isClaimed = true;
						} else if (dateNow < nextClaimDate) {
							console.log('3:', "already claimed");
							this.isClaimed = false;
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
						} else {
							console.log('4:', "already claimed");
							this.isClaimed = true;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})


		}
	},
})

</script>

<style lang="scss" scoped>
.custom-title-left {
	text-transform: uppercase;

}

.icon-text {
	// text with icon in left
	position: relative;
	top: -1px;
	left: 5px;
}

.text {
	font-size: 10px;
	position: relative;

}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>